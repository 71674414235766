<template>
  <el-drawer :visible.sync="visible" title="修改设备信息">
    <el-form
      style="margin: 0 16px"
      label-position="left"
      label-width="80px"
      v-loading="loading"
    >
      <el-form-item label="编号">
        <el-input disabled v-model="form.id"></el-input>
      </el-form-item>
      <el-form-item label="设备编号">
        <el-input v-model="form.deviceNumber"></el-input>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="产品">
        <el-select v-model="form.product.id" style="width: 100%">
          <el-option
            v-for="(item, index) in productList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="拉流地址" v-if="showStreamAddress">
        <el-input placeholder="请输入" v-model="form.rtspUrl"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" :loading="submiting"
          >提交</el-button
        >
        <el-button type="default" @click="visible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  props: {
    productList: { type: Array, required: true },
  },
  data() {
    return {
      visible: false,
      loading: false,
      submiting: false,
      form: {
        id: null,
        deviceNumber: null,
        name: "",
        product: {
          id: null,
        },
      },
    };
  },
  computed: {
    showStreamAddress() {
      return this.productList.find((item) => {
        return item.id === this.form.product.id && item.type === 2;
      });
    },
  },
  methods: {
    edit(id) {
      this.visible = true;
      this.loading = true;
      this.$api.device
        .show(id)
        .then((res) => {
          this.form.id = res.id;
          this.form.deviceNumber = res.deviceNumber;
          this.form.name = res.name;
          this.form.product.id = res.product.id;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.submiting = true;
      this.$api.device
        .update(this.form.id, this.form)
        .then(() => {
          this.$message({ message: "提交成功", type: "success" });
          this.visible = false;
          this.$emit("on-update");
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.submiting = false;
        });
    },
  },
};
</script>