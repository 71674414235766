<template>
  <!-- 设备管理 -->
  <div class="wrapper">
    <el-alert type="success" style="margin: 0 0 16px 0"
      >表示一个实体的硬件设备。</el-alert
    >

    <super-table
      ref="superTable"
      :allow-create="true"
      :allow-import="true"
      :allow-export="true"
      :data="list"
      :total="total"
      :page-size="queryForm.size"
      @page-change="onPageChange"
      @size-change="onSizeChange"
      @selection-change="handleSelectionChange"
    >
      <template slot="actions">
        <!-- <el-button type="default" @click="showAuthorizeDialog = true">
          授权
        </el-button> -->
        <el-button type="default" @click="showGroupDialog = true">
          分组
        </el-button>
      </template>

      <el-form
        :inline="true"
        class="query-form"
        slot="search"
        :model="queryForm"
      >
        <el-form-item prop="keyword">
          <el-input
            placeholder="关键词:设备编号、设备名称"
            v-model="queryForm.keyword"
          ></el-input>
        </el-form-item>
        <el-form-item prop="productId">
          <el-select
            placeholder="产品"
            v-model="queryForm.product.id"
            clearable
          >
            <el-option
              :value="item.id"
              :label="item.name"
              v-for="(item, index) in productList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="groupId">
          <el-select placeholder="分组" v-model="queryForm.group" clearable>
            <el-option
              :value="item.code"
              :label="item.name"
              v-for="(item, index) in groupList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="default" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>

      <el-form
        :model="createForm"
        :rules="createRules"
        slot="create"
        label-position="left"
        label-width="100px"
        style="margin: 0 16px"
        @submit.native.prevent
      >
        <el-form-item label="设备编号" prop="deviceNumber">
          <el-input
            placeholder="请输入..."
            v-model="createForm.deviceNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input
            placeholder="请输入..."
            v-model="createForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品" prop="productId">
          <el-select placeholder="请选择产品" v-model="createForm.product.id">
            <el-option
              v-for="(item, index) in productList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拉流地址" v-if="showStreamAddress">
          <el-input
            placeholder="请输入"
            v-model="createForm.rtspUrl"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="create"
            native-type="submit"
            :loading="isSubmit"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <el-table-column
        type="selection"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column label="编号" prop="id" width="60"></el-table-column>
      <el-table-column
        label="设备编号"
        prop="deviceNumber"
        width="160"
      ></el-table-column>
      <el-table-column
        label="设备名称"
        prop="name"
        width="160"
      ></el-table-column>
      <el-table-column label="产品" prop="name" width="160">
        <template slot-scope="scope">
          {{ scope.row.product.name }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="授权应用" align="center">
        <template slot-scope="scope">
          <el-tag
            v-for="(item, index) in scope.row.apps"
            :key="index"
            closable
            @close="removeAuthorize(scope.row.id, item.id)"
          >
            {{ item.name }}
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="分组" align="center">
        <template slot-scope="scope">
          <el-tag
            v-for="(item, index) in scope.row.groups"
            :key="index"
            closable
            @close="removeDeviceFromGroup(item.id, scope.row.id)"
          >
            {{ item.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="设备状态"
        prop="status"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.status === 0">未注册</el-tag>
          <el-tag type="success" v-if="scope.row.status === 1">在线</el-tag>
          <el-tag type="danger" v-if="scope.row.status === 2">离线</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="createdAt"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="text" class="action" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" class="action" @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </super-table>

    <el-dialog title="授权" :visible.sync="showAuthorizeDialog">
      <el-form
        :model="authorizeForm"
        :rules="authorizeRules"
        label-position="right"
        label-width="100px"
        @submit.native.prevent
      >
        <el-form-item label="应用" prop="appId">
          <el-select v-model="authorizeForm.appId">
            <el-option
              v-for="(item, index) in appList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" native-type="submit" @click="batchAuthorize"
          >确定</el-button
        >
        <el-button type="default" @click="showAuthorizeDialog = false"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="分组" :visible.sync="showGroupDialog">
      <el-form
        :model="groupForm"
        :rules="groupRules"
        label-position="right"
        label-width="100px"
        @submit.native.prevent
      >
        <el-form-item label="分组" prop="groupForm">
          <el-select v-model="groupForm.groupId">
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" native-type="submit" @click="batchGroup">
          确定
        </el-button>
        <el-button type="default" @click="showGroupDialog = false">
          取消
        </el-button>
      </div>
    </el-dialog>
    <edit
      ref="editDialog"
      @on-update="search"
      :product-list="productList"
    ></edit>
  </div>
</template>

<script>
import SuperTable from "@/components/SuperTable/SuperTable";
import edit from "./edit";
export default {
  components: {
    SuperTable,
    edit,
  },
  data() {
    return {
      showAuthorizeDialog: false,
      showGroupDialog: false,
      isSubmit: false,
      productList: [],
      appList: [],
      groupList: [],
      list: [],
      selectItems: [],
      queryForm: {
        keyword: "",
        product: {
          id: null,
        },
        group: null,
        page: 1,
        size: 10,
      },
      createForm: {
        deviceNumber: "",
        name: "",
        rtspUrl: "",
        product: {
          id: null,
        },
      },
      createRules: {
        deviceNumber: { required: true, message: "请输入...", trigger: "blur" },
      },
      authorizeForm: {
        appId: null,
        devices: [],
      },
      authorizeRules: {
        appId: { required: true, message: "请选择分组", trigger: "blur" },
      },
      groupForm: {
        groupId: null,
        devices: [],
      },
      groupRules: {
        groupId: { required: true, message: "请选择分组", trigger: "blur" },
      },
      total: 0,
    };
  },
  async mounted() {
    await this.getProductList();
    await this.getAppList();
    await this.getGroupList();
    this.search();
  },
  computed: {
    showStreamAddress() {
      return this.productList.find((item) => {
        return item.id === this.createForm.product.id && item.type === 2;
      });
    },
  },
  methods: {
    getProductList() {
      this.$api.product
        .all()
        .then((res) => {
          this.productList = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    getAppList() {
      this.$api.app
        .all()
        .then((res) => {
          this.appList = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    getGroupList() {
      this.$api.group
        .all()
        .then((res) => {
          this.groupList = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    search() {
      this.$api.device
        .search(this.queryForm)
        .then((res) => {
          this.list = res.content;
          this.queryForm.size = res.pageable.size;
          this.total = parseInt(res.totalElements);
        })
        .catch((error) => this.$message({ message: error, type: "error" }));
    },
    create() {
      this.isSubmit = true;
      this.$api.device
        .create(this.createForm)
        .then(() => {
          this.$notify({
            message: "添加成功",
            type: "success",
          });
          this.$refs.superTable.closeCreateDialog();
          this.search();
        })
        .catch(() => {
          this.$notify({
            message: "该产品存在设备编号重复",
            type: "error",
          });
        })
        .finally(() => {
          this.isSubmit = false;
        });
    },
    edit(item) {
      this.$refs.editDialog.edit(item.id);
    },
    remove(item) {
      this.$confirm("确定要删除该项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.device
            .destroy(item.id)
            .then((res) => {
              console.log(res);
              this.search();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    onPageChange(val) {
      this.queryForm.page = val;
      this.search();
    },
    onSizeChange(val) {
      this.queryForm.size = val;
      this.search();
    },
    handleSelectionChange(val) {
      this.selectItems = val;
    },
    batchAuthorize() {
      this.$api.app
        .batchAuthorize(
          this.authorizeForm.appId,
          this.selectItems.map((item) => {
            return item.id;
          })
        )
        .then(() => {
          this.$message({ message: "授权成功", type: "success" });
          this.showAuthorizeDialog = false;
          this.search();
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    batchGroup() {
      this.$api.group
        .addDevices(
          this.groupForm.groupId,
          this.selectItems.map((item) => {
            return item.id;
          })
        )
        .then(() => {
          this.$message({ message: "添加分组成功", type: "success" });
          this.showGroupDialog = false;
          this.search();
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    getProductName(productId) {
      const product = this.productList.find((item) => {
        return item.id === productId;
      });

      return product ? product.name : "<未知产品>";
    },
    removeAuthorize(appId, deviceId) {
      this.$confirm("确定要取消该授权吗？", "取消授权", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.app.cancelAuthorize(appId, deviceId).then(() => {
          this.$message({ message: "操作成功", type: "success" });
          this.search();
        });
      });
    },
    removeDeviceFromGroup(groupId, deviceId) {
      this.$confirm("确定要将该设备移除分组吗？", "移除分组", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.group.removeDevice(groupId, deviceId).then(() => {
          this.$message({ message: "操作成功", type: "success" });
          this.search();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}
</style>
